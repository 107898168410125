/*----------------------------------------------------------------------------*/
/*  # TABLES                                                                  */
/*----------------------------------------------------------------------------*/
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 1.8rem 0 2.5rem;
  width: 100%;
  caption {
    caption-side: bottom;
    font-family: var(--font_250);
    font-size: var(--font-size_xs);
    padding: 1rem 0 0;
    text-align: left;
    }
  th {
    background: var(--color_white-0);
    border-bottom: 0.1rem solid var(--color_grey-2);
    box-shadow: 0 .05rem 0 0 var(--color_grey-2);
    font-family: var(--font_500condensed);
    padding: .5rem;
    text-align: left;
    vertical-align: top;
    position: sticky;
    top: 0;
    z-index: 2;
    small {
      font-family: var(--font_300);
      font-size: var(--font-size_xxs);
    }
  }
  tr {
    border-bottom: 0.004rem solid var(--color_grey-2);
    margin: 0;
    padding: 0;
    &:nth-child(even) {
      background: var(--color_grey-2);
    }
  }
  td {
    font-family: var(--font_300);
    font-size: calc(100% - .1rem);
    padding: .5rem .5rem .3rem;
    vertical-align: top;
  }
}
