/*----------------------------------------------------------------------------*/
/*  #ERROR PAGES                                                              */
/*----------------------------------------------------------------------------*/
.error {
    font-family: var(--font_300);
  &__thank-you {
    display: block;
    font-family: var(--font_400);
    font-size: var(--font-size_l);
    margin: 1.5rem 0 1.2rem;
  }
}
