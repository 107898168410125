/*----------------------------------------------------------------------------*/
/*  #SLIDER                                                                   */
/*----------------------------------------------------------------------------*/
/* Variables */
:root {
  --slider_cubic-bezier: cubic-bezier(0.77, 0, 0.175, 1);
  --slider_animation: 12300ms infinite;
}

/* Slider */
.slider {
  border: none;
  margin: 0 0 2.25rem 0;
  .slides {
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    .slides-inner {
      width: 500%;
      transition: all 800ms var(--slider_cubic-bezier);
      transition-timing-function: var(--slider_cubic-bezier);
      .slide {
        width: 20%;
        float: left;
        &--content {
          background: var(--color_white-1);
          padding: 2.2rem 1.8rem;
          position: absolute;
          top: 1rem;
          width: 25%;
          .title {
            color: var(--color_blue-0);
            display: block;
            font-family: var(--font_300);
            font-size: var(--font-size_xxl);
          }
          p {
            color: var(--color_black-0);
            font-family: var(--font_300);
            font-size: var(--font-size_l);
          }
        }
        .image {
          background: url(/assets/images/slider-1.jpg);
          background-position: 0% 10%;
          background-size: cover;
          height: 20rem;
          position: relative;
          width: 89vw;
        }
      }
    }
    label {
      color: var(--color_black-0);
    }
  }
  /* Move slides overflowed container */
  #slide1:checked ~ .slides .slides-inner {
    margin-left: 0;
  }
  #slide2:checked ~ .slides .slides-inner {
    margin-left: -100%;
  }
  #slide3:checked ~ .slides .slides-inner {
    margin-left: -200%;
  }
  article,
  .fake-radio,
  .radio-btn {
    transition: all 0.5s ease-out;
  }
  .fake-radio {
    text-align: center;
  }
}


#slide1:checked ~ div .fake-radio .radio-btn:nth-child(1),
#slide2:checked ~ div .fake-radio .radio-btn:nth-child(2),
#slide3:checked ~ div .fake-radio .radio-btn:nth-child(3) {
  background: var(--color_blue-0);
}

.radio-btn {
  background: var(--color_white-0);
  border-radius: 50%;
  border: 0.004rem solid var(--color_blue-0);
  cursor: pointer;
  display: inline-block !important;
  height: 0.7rem;
  margin: .4rem 0.1rem 0 0.1rem;
  width: 0.7rem;
}


/* Text of slides */
#slide1:checked ~ .labels .label:nth-child(1),
#slide2:checked ~ .labels .label:nth-child(2),
#slide3:checked ~ .labels .label:nth-child(3) {
  opacity: 1;
}

.label {
  opacity: 0;
  position: absolute;
}
/* Text of slides - END */

/* Calculate AUTOPLAY for BULLETS */
@keyframes bullet {
  0%,
  33.32333333333334% {
    background: var(--color_blue-0);
  }
  33.333333333333336%,
  100% {
    background: var(--color_white-0);
  }
}

#play1:checked ~ div .fake-radio .radio-btn:nth-child(1) {
  animation: bullet var(--slider_animation) -1000ms;
}

#play1:checked ~ div .fake-radio .radio-btn:nth-child(2) {
  animation: bullet var(--slider_animation) 3100ms;
}

#play1:checked ~ div .fake-radio .radio-btn:nth-child(3) {
  animation: bullet var(--slider_animation) 7200ms;
}
/* Calculate AUTOPLAY for BULLETS - END */

/* Calculate AUTOPLAY for SLIDES */
@keyframes slide {
  0%,
  25.203252032520325% {
    margin-left: 0;
  }
  33.333333333333336%,
  58.53658536585366% {
    margin-left: -100%;
  }
  66.66666666666667%,
  91.869918699187% {
    margin-left: -200%;
  }
}

.slider > #play1:checked ~ .slides .slides-inner {
  animation: slide var(--slider_animation);
}
/* Calculate AUTOPLAY for SLIDES - END */

/* Calculate AUTOPLAY for CAPTION */
@keyframes caption {
  0%,
  33.32333333333334% {
    opacity: 1;
  }
  33.333333333333336%,
  100% {
    opacity: 0;
  }
}

#play1:checked ~ .labels .label:nth-child(1) {
  animation: caption var(--slider_animation) -1000ms;
}

#play1:checked ~ .labels .label:nth-child(2) {
  animation: caption var(--slider_animation) 3100ms;
}

#play1:checked ~ .labels .label:nth-child(3) {
  animation: caption var(--slider_animation) 7200ms;
}
