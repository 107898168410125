/*----------------------------------------------------------------------------*/
/*  #SITEMAP                                                                  */
/*----------------------------------------------------------------------------*/
.sitemap {
  list-style: inside none;
  margin: 2rem 0 0 0;
  padding: 0;
  &__list-item {
    margin: 0 0 1rem 0;
  }
  &__title {
    color: var(--color_black-0);
    font-family: var(--font_400);
    font-size: var(--font_m);
  }
  &__url {
    display: block;
    font-family: var(--font_300);
    font-size: var(--font-size_xs);
  }
}
