/*----------------------------------------------------------------------------*/
/*  #BUTTONS                                                                  */
/*----------------------------------------------------------------------------*/
/* Anchors & Buttons */
a.button,
a:link.button,
a:visited.button,
button.button {
  align-items: center;
  border: none;
  border-radius: .15rem;
  cursor: pointer;
  display: inline-flex;
  font-family: var(--font_400);
  justify-content: center;
  line-height: 1.3;
  min-width: 10ch;
  padding: .5rem 1rem .4rem;
  text-align: center;
  transition: 220ms all ease-in-out;
  &__contained {
    background: var(--color_blue-0);
    color: var(--color_white-0);
    &:hover, &:focus {
      color: var(--color_white-0);
    }
    &--disabled {
      background: var(--color_grey-6);
      color: var(--color_white-0);
      &:hover, &:focus {
        color: var(--color_white-0);
        cursor: not-allowed;
      }
    }
  }
  &__outlined {
    background: var(--color_white-0);
    border: 0.08rem solid var(--color_blue-0);
    color: var(--color_blue-0);
    &--hovered, &:hover {
      background: var(--color_blue-0);
      color: var(--color_white-0);
      text-decoration: none;
    }
    &--disabled {
      border: 0.08rem solid var(--color_grey-6);
      color: var(--color_grey-6);
      &:hover, &:focus {
        color: var(--color_grey-0);
        cursor: not-allowed;
      }
    }

  }

  @media (max-width: 550px) {
    /* https://www.w3.org/WAI/WCAG21/Understanding/target-size.html */
    min-height: 2.75rem;
  }
}

/* Anchors */
a.button, 
a:link.button {
  box-sizing: border-box;
}





/*----------------------------------------------------------------------------*/
/*  #BUTTON-LIST                                                              */
/*----------------------------------------------------------------------------*/
.button-list {
 list-style: outside none; 
 margin: 0;
 padding: 0;
 li {
  margin: 0 0 1.4rem;
  padding: 0;
 }
}
