/*----------------------------------------------------------------------------*/
/*  #LISTS                                                                    */
/*----------------------------------------------------------------------------*/
/* Content lists */
main {
  ul {
    list-style: none;
    margin: 1.2rem 0 1.2rem -1.2rem;
    li {
      font-family: var(--font_300);
      font-size: var(--font-size_m);
      &::before {
         content: "\2022";
         color: var(--color_blue-0);
         display: inline-block;
         margin-left: -1rem;
         width: 1.2rem;
      }
    }
  }
}
