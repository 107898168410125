/*----------------------------------------------------------------------------*/
/*  #BOXES                                                                    */
/*----------------------------------------------------------------------------*/
.teaser-boxes {
  border-top: 0.04rem solid var(--color_grey-2);
  display: flex;
  flex-wrap: wrap;
  gap: 1.6rem;
  margin: 2.4rem 0 2.4rem 0;
  padding: 3rem 0 0 0;
  .teaser-box {
    background: var(--grad-4);
    box-shadow: 0 0.1rem 0.2rem 0 var(--color_grey-2);
    position: relative;
    width: calc(100% / 3 - 1.07rem);
    &:hover {
      box-shadow: 0 0.1rem 0.2rem 0 var(--color_grey-1);
    }
    @media (max-width: 60rem) {                                      /* 960px */
      width: calc(100% / 2 - 0.8rem);
    }
    @media (max-width: 30rem) {                                      /* 480px */
      width: 100%;
    }
    &__anchor {
      &:hover {
        color: var(--color_blue-0);
        text-decoration: none;
      }
    }
    &__image {
      background: var(--grad-0);
      height: auto;
      object-fit: cover;
      width: 100%;
    }
    &__content {
      margin: 0 0 1.25rem;
      padding: 1.2rem 0.8rem 0 0.8rem;
    }
    &__topic {
      color: var(--color_grey-5);
      display: block;
      font-family: var(--font_300);
      font-size: var(--font-size_xs);
      margin: 0 0 .4rem 0;
    }
    &__heading {
      display: block;
      font-family: var(--font_400);
      font-size: var(--font-size_l);
      margin: 0 0 1.4rem 0;
      padding: 0 0 0 0.6rem;
      position: relative;
      &:before {
        border-bottom: 0.12rem solid var(--color_yellow-0);
        bottom: 0;
        content: '';
        position: absolute;
        left: 0;
        top: 120%;
        width: 4rem;
      }
    }
    &__description {
      color: var(--color_black-0);
      font-family: var(--font_300);
      font-size: var(--font-size_s);
    }
  }
}

.teaser + .teaser-boxes {
  border-top: none;
  margin: 0;
  padding: 1rem 0 0 0;
}
