/*----------------------------------------------------------------------------*/
/*  #FONT SIZE VARIABLES                                                      */
/*----------------------------------------------------------------------------*/
:root {
  --font-size_xxs:  0.8rem;
  --font-size_xs:   0.9rem;
  --font-size_s:    1.0rem;
  --font-size_m:    1.063rem;    /* Default font-size                         */
  --font-size_l:    1.125rem;
  --font-size_xl:   1.2rem;
  --font-size_xxl:  1.4rem;
  --font-size_xxxl: 1.9rem;
}
