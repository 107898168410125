/*----------------------------------------------------------------------------*/
/*  #TYPEFACE VARIABLES                                                       */
/*----------------------------------------------------------------------------*/

/* Thin - 100 */
:root {
  --font_100: '100';
}
@font-face {
  font-family: '100';
  font-display: fallback;
  src: url('https://cdn.aws.olympus.eu/static/fonts/noto/NotoSans-Thin.woff2') format('woff2');
}

:root {
  --font_100italic: '100italic';
}
@font-face {
  font-family: '100italic';
  font-display: fallback;
  src: url('https://cdn.aws.olympus.eu/static/fonts/noto/NotoSans-ThinItalic.woff2') format('woff2');
}

/* Light - 300 */
:root {
  --font_300: '300';
}
@font-face {
  font-family: '300';
  font-display: fallback;
  src: url('https://cdn.aws.olympus.eu/static/fonts/noto/NotoSans-Light.woff2') format('woff2');
}

:root {
  --font_300italic: '300italic';
}
@font-face {
  font-family: '300italic';
  font-display: fallback;
  src: url('https://cdn.aws.olympus.eu/static/fonts/noto/NotoSans-LightItalic.woff2') format('woff2');
}

/* Regular - 400 */
:root {
  --font_400: '400';
}
@font-face {
  font-family: '400';
  font-display: fallback;
  src: url('https://cdn.aws.olympus.eu/static/fonts/noto/NotoSans-Regular.woff2') format('woff2');
}

:root {
  --font_400italic: '400italic';
}
@font-face {
  font-family: '400italic';
  font-display: fallback;
  src: url('https://cdn.aws.olympus.eu/static/fonts/noto/NotoSans-Italic.woff2') format('woff2');
}

/* Medium - 500 */
:root {
  --font_500: '500';
}
@font-face {
  font-family: '500';
  font-display: fallback;
  src: url('https://cdn.aws.olympus.eu/static/fonts/noto/NotoSans-Medium.woff2') format('woff2');
}

:root {
  --font_500italic: '500italic';
}
@font-face {
  font-family: '500italic';
  font-display: fallback;
  src: url('https://cdn.aws.olympus.eu/static/fonts/noto/NotoSans-MediumItalic.woff2') format('woff2');
}

/* Bold - 700 */
:root {
  --font_700: '700';
}
@font-face {
  font-family: '700';
  font-display: fallback;
  src: url('https://cdn.aws.olympus.eu/static/fonts/noto/NotoSans-Bold.woff2') format('woff2');
}

:root {
  --font_700italic: '700italic';
}
@font-face {
  font-family: '700italic';
  font-display: fallback;
  src: url('https://cdn.aws.olympus.eu/static/fonts/noto/NotoSans-BoldItalic.woff2') format('woff2');
}
