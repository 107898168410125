.language-switch {
  float: right;
  select,
  &__select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: var(--color_white-0);
    border: none;
    color: var(--color_grey-3);
    display: inline;
    font-size: .8rem;
    line-height: 1.4;
    margin: 0;
    outline: none;
    padding: .1rem .3rem;
    &:active,
    &:focus,
    &:hover {
      color: var(--color_black-0);
      outline: initial;
    }
  }
}
