/*----------------------------------------------------------------------------*/
/*  #GRADIENT VARIABLES                                                       */
/*----------------------------------------------------------------------------*/
:root {
  --grad-0: linear-gradient(                  /* news box background          */
              to right,                       /*                              */
              rgba(8, 16, 123, 1) 0,          /* olympus ci blue    - #08107B */
              rgba(34, 40, 136, 1) 100%);     /*                    - #222888 */
  --grad-1: linear-gradient(                  /* cta background               */
              to left,                        /*                              */
              rgba(220, 229, 237, 1),         /*                    - #DCE5ED */
              rgba(242, 244, 246, 1));        /*                    - #F2F4F6 */
  --grad-2: radial-gradient(                  /* contact us background        */
              circle,                         /*                              */
              rgba(242, 244, 246, 1),         /*                    - #F2F4F6 */
              rgba(220, 229, 237, 1));        /*                    - #DCE5ED */
}
