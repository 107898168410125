/*----------------------------------------------------------------------------*/
/*  #COLOR VARIABLES                                                          */
/*----------------------------------------------------------------------------*/
:root {
  --color_blue-0:   rgba(8, 16, 123, 1);      /* olympus ci blue    - #08107B */
  --color_blue-1:   rgba(34, 40, 136, 1);     /* copy background    - #E3E9F0 */
  --color_yellow-0: rgba(228, 153, 0, 1);     /* olympus ci yellow  - #E49900 */
  --color_yellow-1: rgba(208, 133, 0, 1);     /* accessible yellow  - #D08500 */
  --color_black-0:  rgba(0, 0, 0, 1);         /* black              - #000000 */
  --color_white-0:  rgba(255, 255, 255, 1);   /* olympus ci white   - #FFFFFF */
  --color_white-1:  rgba(255,255,255,.8);     /* copy background    - #FFFFFF */
  --color_grey-0:   rgba(119, 119, 119, 1);   /* olympus ci grey    - #777777 */
  --color_grey-1:   rgba(167, 167, 167, 1);   /* decoration         - #A7A7A7 */
  --color_grey-2:   rgba(221,221,221);        /* copy text          - #444444 */
  --color_grey-3:   rgba(63, 63, 63, 1);      /* footer background  - #3F3F3F */
  --color_grey-4:   rgba(40, 40, 40, 1);      /* footer background  - #282828 */
  --color_grey-5:   rgba(89, 89, 89, 1);      /* accessible small   - #595959 */
  --color_grey-6:   rgba(118, 118, 118, 1);   /* accessible normal  - #767676 */
  --color_grey-7:   rgba(149, 149, 149, 1);   /* accessible large   - #959595 */
}
